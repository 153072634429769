import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitButton", "form", "input"];

  selectOption() {
    this.submitButtonTarget.disabled = false;
    if (event.target.value == 'yes') {
      this.formTarget.classList.remove("hidden");
      this.inputTargets.forEach((el) => el.required = true);
    } else if (event.target.value == 'no') {
      this.formTarget.classList.add("hidden");
      this.inputTargets.forEach((el) => el.required = false);
    }
  }
}
