import * as d3 from "d3";

window.makeDonut = function(categories, totalCount){
  // set the dimensions and margins of the graph
  const width = 450,
        height = 450;

  // The radius of the pieplot is half the width or half the height (smallest
  // one). I subtract a bit of margin.
  const radius = Math.min(width, height) / 2;

  // this is used for the completed mark total
  const totalComplete = categories.reduce((acc, c) => acc + c.completed_count, 0);

  // these are the colours used for the arc segments
  const collaboration_blue = "#AFDAE6";
  const communication_pink = "#F1C9DC";
  const education_green = "#C8DCD0";
  const practice_and_policy_yellow = "#F9DD4B";

  // Set up the area. Use preserveAspectRatio to make it responsive.
  const svg = d3.select("#category-donut-container")
    .append("svg")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
    .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

  // Set the colors up. Make sure these match the order of categories defined
  // in the Recommendation enum category, as D3 will effectively iterate
  // through these assigning them to categories as you pass new categories in
  // to the loop.
  const color = d3.scaleOrdinal()
    .range([collaboration_blue, communication_pink, education_green, practice_and_policy_yellow])

  // Compute the position of each group on the pie.
  // .sort call stops d3 from sorting in numerical order
  // .value provides the function to extract the value from the data.
  const pie = d3.pie()
    .value(d => d.total_count)
    .sort(() => -1)
    (categories);

  // Add the 'Completed' label
  svg
    .append("text")
    .text("Completed")
    .style("text-anchor", "middle")
    .style("dominant-baseline", "auto")
    .attr("y", -35)
    .attr("font-size", "30px")
    .attr("font-family", "Raleway")
    .attr("font-weight", "400")

  // and the actual number completed
  svg
    .append("text")
    .text(`${totalComplete}/${totalCount}`)
    .style("text-anchor", "middle")
    .style("dominant-baseline", "hanging")
    .attr("y", -10)
    .attr("font-size", "80px")
    .attr("font-family", "Inter")
    .attr("font-weight", "600")

  // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
  svg
    .selectAll('donut-arc')
    .data(pie)
    .join('path')
    .attr('d', d3.arc()
      .innerRadius(160)         // This is the size of the donut hole
      .outerRadius(radius)
    )
    .attr('fill', d => color(d.data.name) )
}
