// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Polyfill all the core JS stuff to get basic IE11 support
import 'core-js';

// core-js doesn't include Element#closest
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

// We need the customElements polyfill for Edge 18.
import '@webcomponents/custom-elements';
// CSS.escape polyfill for Edge 18.
require('css.escape');

// Polyfill fetch too
import 'whatwg-fetch';

import { Turbo, cable } from "@hotwired/turbo-rails";

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("trix")
require("@rails/actiontext")
// If you're adding more admin-only JS, consider refactoring this out
// into a separate entry point; we just didn't have time when adding trix.


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'
import 'donut'
