import { Controller } from "stimulus"

// This openDropdown event fires when the user clicks the
// #previous_assessements_dropdown button dropdown on the employee results page.
export default class extends Controller {
  static targets = [ "dropdownToggle", "previousAssessementsDropdown", "downChevron", "upChevron" ]

  openDropdown() {
    this.previousAssessementsDropdownTarget.classList.toggle("hidden");
    this.downChevronTarget.classList.toggle("hidden");
    this.upChevronTarget.classList.toggle("hidden");
  }

  // This function is called when a user clicks within the window.
  // Unless the user clicks on the dropdown or dropdown button, this function
  // will hide the dropdown.
  handleClick(event) {
    if (event) {
      const dropdownClicked = this.previousAssessementsDropdownTarget.contains(event.target);
      const dropdownToggleClicked = this.dropdownToggleTarget.contains(event.target);
      // If the click event was on the dropdown button or the dropdown itself,
      // return early.
      if (dropdownClicked || dropdownToggleClicked) { return; }
    }

    // Otherwise hide the dropdown.
    this.hideDropdown();
  }

  // Hides the dropdown
  hideDropdown() {
    this.previousAssessementsDropdownTarget.classList.add("hidden");
    this.downChevronTarget.classList.remove("hidden");
    this.upChevronTarget.classList.add("hidden");
  }

  // The function is called when the `turbo:before-cache` event fires.
  // See ./packs/controllers/index.js
  beforeCache() {
    this.hideDropdown()
  }
}
