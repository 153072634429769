import { Controller } from "stimulus"

// This class is used to open the action plan item modal.
// NOTE: If you are looking for the stimulus controller that expands action plan
//       items on the action plan page, it's the action-plan-controller.js
export default class extends Controller {
  static targets = [ 'openModal', 'modal', 'submit', 'form' ];
  static values = {
    recommendationId: String,
    createPath: String,
    updatePath: String,
    itemType: String,
  };

  toggleModal() {
    this.modalTarget.classList.toggle("hidden");
  }

  async create(event) {
    // NOTE: This is super dodgey. 😬
    // If the form is invalid, we return before preventDefault(),
    // triggering the HTML 5 validations. As the form is invalid, these
    // should stop the form being submitted. If the form is valid, we
    // preventDefault() and make an ajax call.
    if(!this.formTarget.checkValidity()) { return; }

    event.preventDefault();
    const originalButtonCopy = this.submitTarget.innerHTML;

    const formData = new FormData(this.formTarget);

    // Modify button so that it is in the "pending" state
    this.submitTarget.disabled = true;
    this.submitTarget.value = 'Adding to plan ...';
    this.submitTarget.classList.replace('black', 'grey');
    this.submitTarget.classList.add('cursor-default');


    const response = await fetch(this.createPathValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: formData
    });

    // These timeouts are here for aesthetic reasons.
    setTimeout(
      response => {
        if (response.ok) {
          // Modify button so that it is in the "success" state
          this.submitTarget.value = 'Added to plan';
          this.submitTarget.classList.replace('grey', 'green');

          switch (this.itemTypeValue) {
            case 'custom':
              // Relaod the page.
              location.reload();
              break;
            case 'recommendation':
              // Modify the button on the recommendation.
              this.openModalTarget.innerHTML = 'Added to plan';
              this.openModalTarget.classList.replace('black', 'green');
              this.openModalTarget.classList.add('cursor-default');
              this.openModalTarget.disabled = true;
              // Remove the modal from the DOM.
              setTimeout(() => this.modalTarget.remove(), 1000);
              break;
          }
        } else {
          // Raise an alert and reset the button
          alert("Something went wrong. Please try adding the recommendation to your action plan again.");
          this.submitTarget.disabled = false;
          this.submitTarget.value = originalButtonCopy;
          this.submitTarget.classList.replace('grey', 'black');
          this.submitTarget.classList.remove('cursor-default');
        }
      },
      1000,
      response
    );
  }

  async update(event) {
    if(!this.formTarget.checkValidity()) { return; }

    event.preventDefault();

    const formData = new FormData(this.formTarget);

    // Modify button so that it is in the "pending" state
    this.submitTarget.disabled = true;
    this.submitTarget.value = 'Updating item...';
    this.submitTarget.classList.replace('black', 'grey');
    this.submitTarget.classList.add('cursor-default');

    const response = await fetch(this.updatePathValue, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "X-Requested-With": 'XMLHttpRequest'
      },
      body: formData
    });

    setTimeout(
      response => {
        if (response.ok) {
          // Modify button so that it is in the "success" state
          this.submitTarget.value = 'Item updated';
          this.submitTarget.classList.replace('grey', 'green');
          setTimeout(() => location.reload(), 1000);
        } else {
          alert("Something went wrong. Please try updating your action plan again.");

          this.submitTarget.disabled = false;
          this.submitTarget.classList.replace('grey', 'black');
          this.submitTarget.classList.remove('cursor-default');
          this.submitTarget.value = 'Save';
        }
      },
      1000,
      response
    )
  }
}
