import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['content', 'trigger']

  show(e) {
    if (!this.hasContentTarget) { return; }

    this.contentTarget.classList.remove('hidden');
    this.popperInstance = createPopper(
      this.triggerTarget,
      this.contentTarget,
      {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }
    );

    // Watch for clicks elsewhere
    this.clickListener = document.addEventListener('click', (e) => {
      // Not if they're clicking on the trigger, or the content
      if (!e.target.closest("[data-target='tooltip.trigger']") &&
          !e.target.closest("[data-target='tooltip.content']")) {
        this.hide();
      }
    });
  }

  hide() {
    if (!this.hasContentTarget) { return; }

    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
    if (this.clickListener) {
      document.removeEventListener('click', this.clickListener);
    }
    this.contentTarget.classList.add('hidden');
  }

  disconnect() {
    this.hide();
  }
}
