import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['source', 'button'];

  connect() {
    this.timer = null;
  }

  copy() {
    clearInterval(this.timer);

    this.sourceTarget.select();
    document.execCommand('copy');
    this.buttonTarget.innerHTML = 'Copied!';

    this.timer = setTimeout(() => {
      this.buttonTarget.innerHTML = 'Copy';
    }, 3000);
  }
}

