import { Controller } from "stimulus";

// Little helper controller for toggling a dropdown open/closed.
// Just add a 'dropdown' target and 'hidden' class to the content of the
// dropdown, and 'toggleDropdown' action to the dropdown button.
export default class extends Controller {
  static targets = ["dropdown"];

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('hidden');
  }
}
