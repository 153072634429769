import { Controller } from 'stimulus';

// Controls behaviour for demographics filters when viewing results.
export default class extends Controller {
  static targets = ['filter', 'dataContainer', 'all'];
  static values = { url: String, comparison: String, activeAssessmentYear: String };

  initialize() {
    this.activeAssessmentYear = this.activeAssessmentYearValue;
    this.isAllTab = false; // initially we want to display the top 3 / bottom 3 tab for comparisons.
  }

  fetchDemographics(filters) {
    const url = new URL(this.urlValue);

    url.searchParams.append("filters", filters);
    url.searchParams.append("assessment_year", this.activeAssessmentYear);
    url.searchParams.append("all_tab", this.isAllTab);

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'X-CSRF-Token': Rails.csrfToken()
      }
    }).then(response => response.text())
      .then(data => {
        this.dataContainerTargets.forEach((container) => { container.innerHTML = data });
      })
      .catch(console.error);
  }

  // Called when switching tabs in the year-on-year view to remember the active tab when reloading the partial.
  setAttributes(event) {
    this.activeAssessmentYear = event.target.attributes.year.value;
    this.isAllTab = event.target.attributes.all.value;
  }

  applyFilter(event) {
    this.handleAllOption();

    // Need to keep mobile and desktop filters in sync to avoid bugs when re-rendering page after the request.
    this.filterTargets.forEach((filter) => {
      if (event.target.value === filter.value) {
        filter.checked = event.target.checked;
      }
    });

    // find the currently selected filters.
    this.fetchDemographics(this.selectedFilters());
  }

  // reset all filters to get fetch demographics.
  resetFilters(event) {
    this.allTargets.forEach((allFilter) => { allFilter.checked = true });
    this.filterTargets.forEach((filter) => filter.checked = false);
    this.fetchDemographics([]);
  }

  selectedFilters() {
    const selected = [];
    this.filterTargets.forEach((filter) => { filter.checked && selected.push(filter.value) });
    return selected;
  }

  // Keep the all option selected if there are no filters applied.
  handleAllOption() {
    this.allTargets.forEach(allFilter => { allFilter.checked = this.selectedFilters().length === 0; });
  }
}
