import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wizard", "step1"];

  initialize() {
    //this.wizardTarget.classList.remove("hidden");
  }

  closeWizard() {
    this.wizardTarget.classList.add("hidden");
  }

}
