import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['commentBox', 'comment', 'radioButton', 'icon'];

  connect() {
    if (this.radioButtonTargets.find(e => e.checked)) {
      this.enableIcon();
    } else {
      this.disableIcon();
    }
  }
  
  openCommentBox() {
    if (this.radioButtonTargets.find(e => e.checked)) {
      // Find other comment boxes and close them
      document.querySelectorAll('[data-controller=comments]').forEach((commentBox) => {
        this.application.getControllerForElementAndIdentifier(commentBox, 'comments').closeCommentBox();
      });

      this.commentBoxTarget.classList.remove('hidden');
    }
  }

  closeCommentBox() {
    this.commentBoxTarget.classList.add('hidden');
  }

  // This is a bit of a cheat; submit the form nearby when you 'save' a
  // free text comment.
  submitForm(e) {
    // Disable the button they just clicked.
    e.currentTarget.setAttribute('disabled', true);
    // And faux-submit the form
    document.getElementById('js-save-button').click();
  }

  enableIcon() {
    this.iconTargets.forEach(e => e.classList.remove('opacity-50'));
  }

  disableIcon() {
    this.iconTargets.forEach(e => e.classList.add('opacity-50'));
  }
}

