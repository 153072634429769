//  We are using stimulus to remove the red border that appears when we do not answer required questions during assessments

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["parentBox"];

  removeHighlight() {
    this.parentBoxTarget.classList.remove(
      "border",
      "rounded",
      "border-2",
      "border-red"
    );
  }
}
