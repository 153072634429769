import { Controller } from 'stimulus'

// For unfolding demographics when the user chooses a certain option, e.g.  if
// they say they have a disability or additional needs, unfold the additional
// questions for those demographics.
export default class extends Controller {
  static targets = ['unfoldIfSelected', 'unfolding']

  connect() {
    // Trigger on load so we get the right unfolded/folded state.
    this.change();
  }

  // Event handler - bind all radio buttons to this event, and it'll check what
  // state the unfolding demographics need to be in. It's a bit blunt, but it
  // saves on the complicated logic of checking which radio button was selected
  // _and_ still considering other radio buttons that mean the demographics
  // need to unfold.
  change() {
    this.unfoldIfSelectedTargets.forEach((elem) => {
      if (elem.checked) { this.open() }
    });

    // Sanity check, ensure they're closed. Trying to avoid any weird
    // turbolinks caching issues.
    if (this.unfoldIfSelectedTargets.every((elem) => !elem.checked)) {
      this.close();
    }
  }

  open() {
    this.unfoldingTarget.classList.remove('hidden');
  }

  close() {
    this.unfoldingTarget.classList.add('hidden');

    // Uncheck everything within the unfolding answers so they don't
    // accidentally get submitted. It also feels weird if it folds & then
    // unfolds again and your answers are still there.
    const inputs = this.unfoldingTarget.querySelectorAll('input[type=radio]');
    inputs.forEach((input) => input.checked = false);
  }

}
