import { Controller } from "stimulus";

// This class is used to expands action plan items on the action plan page.
export default class extends Controller {
  static targets = ["details", "summary", "notesSummary", "markCompleted", "backToActionPlan"];

  toggleDetails(event) {
    // If the user clicks on the actual button,
    // we don't want to expand the action plan details.
    const doNotExpand = event.target.closest(".js-do-not-expand");
    if (doNotExpand) { return; }

    this.detailsTarget.classList.toggle("hidden");
    this.summaryTarget.classList.toggle("summary-expanded");
    if (this.hasNotesSummaryTarget) {
      this.notesSummaryTarget.classList.toggle("invisible");
    }
    if (this.hasMarkCompletedTarget) {
      this.markCompletedTarget.classList.toggle("invisible");
    }
    if (this.hasBackToActionPlanTarget) {
      this.backToActionPlanTarget.classList.toggle("invisible");
    }
  }
}
