import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']

  connect() {
    this.activeTabClasses = this.data.get('activeTab').split(' ')
    this.showTab()
  }

  change(event) {
    event.preventDefault()

    this.activeIndex = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]

      if (index === this.activeIndex) {
        panel.classList.remove('hidden')
        tab.classList.add(...this.activeTabClasses)

      } else {
        panel.classList.add('hidden')
        tab.classList.remove(...this.activeTabClasses)
      }
    })
  }

  get activeIndex() {
    return parseInt(this.data.get('active-index') || 0)
  }

  set activeIndex(value) {
    this.data.set('active-index', value)
    this.showTab()
  }
}
