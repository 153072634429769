import { Controller } from 'stimulus'

// This openDropdown event fires when the user clicks the
// admin-actions dropdown button the admin users index page.
export default class extends Controller {
  static targets = [ 'dropdownToggle', 'dropdown', 'chevron' ]

  // Opens the dropdown
  openDropdown() {
    this.dropdownTarget.classList.toggle('hidden');
    this.toggleChevron()
  }

  // This function is called when a user clicks within the window.
  // Unless the user clicks on the dropdown or dropdown button, this function
  // will hide the dropdown.
  handleClick(event) {
    if (event) {
      const dropdownToggleClicked = this.dropdownToggleTarget.contains(event.target);
      const dropdownClicked = this.dropdownTarget.contains(event.target);
      // If the click event was on the dropdown button or the dropdown itself,
      // return early.
      if (dropdownClicked || dropdownToggleClicked) { return; }
    }

    // Otherwise hide the dropdown.
    this.hideDropdown();
  }

  // Hides the dropdown
  hideDropdown() {
    this.dropdownTarget.classList.add('hidden');
    this.chevronDown();
  }

  // NOTE: Annoyingly, I can't toggle the chevron rotations with .toggle().
  //       So I've made this set of functions.
  toggleChevron() {
    if (this.isChevronDown()) {
      this.chevronUp();
    } else {
      this.chevronDown();
    }
  }

  // Returns a bool for whether the chevron is pointing down.
  isChevronDown() {
    return !this.chevronTarget.classList.contains('rotate-180');
  }

  // Rotates the chevron so it points up.
  chevronUp() {
    this.chevronTarget.classList.add('rotate-180');
    this.chevronTarget.classList.remove('rotate-0');
  }

  // Rotates the chevron so it points down.
  chevronDown() {
    this.chevronTarget.classList.remove('rotate-180');
    this.chevronTarget.classList.add('rotate-0');
  }

  // The function is called when the `turbo:before-cache` event fires.
  // See ./packs/controllers/index.js
  beforeCache() {
    this.hideDropdown()
  }
}
