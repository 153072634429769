import { Controller } from "stimulus"
import {loadStripe} from '@stripe/stripe-js';

// Redirects the user to the Stripe checkout page.
export default class extends Controller {
  static targets = ["autoredirect", "redirectButton"]

  connect() {
    // If the autoredirect target is on the page,
    // automatically redirect the user to the payment page.
    if (this.hasAutoredirectTarget) {
      // We intentionally delay the user 2 seconds before the redirect.
      setTimeout(() => this.visitCheckout(), 2000)
    }
  }

  async visitCheckout() {
    const checkoutSessionPath = this.redirectButtonTarget.dataset.checkoutSessionUrl
    // Fetch a new Checkout session id
    const response = await fetch(checkoutSessionPath, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        'Content-Type': 'application/json'
      }
    });
    const parsedReponse = await response.json();

    const stripePublicKey = parsedReponse.public_key
    const stripe = await loadStripe(stripePublicKey);
    const checkoutSessionId = parsedReponse.session.id
    stripe.redirectToCheckout({ sessionId: checkoutSessionId })
  }
}
