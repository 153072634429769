import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details", "section", "question", "questionDetails"];

  expandDetails() {
    this.detailsTarget.classList.toggle("hidden");
    this.sectionTarget.classList.toggle("section-expanded");
  }

  expandQuestionDetails(event) {
    const currentQuestionNumber = event.currentTarget.dataset.questionNumber;
    const questionDetails = this.questionDetailsTargets.find(
      questionDetails => questionDetails.dataset.questionNumber == currentQuestionNumber
    );
    questionDetails.classList.toggle("hidden");
    const question = this.questionTargets.find(
      question => question.dataset.questionNumber == currentQuestionNumber
    );
    question.classList.toggle("question-expanded");
  }
}
