import { Controller } from 'stimulus';

// Responsible for opening & closing the off-canvas navigation on mobile
export default class extends Controller {
  static targets = ['menu', 'openIcon', 'closeIcon', 'page']

  toggle(e) {
    e.preventDefault();
    // Check the width of the mobile nav for this magic number.
    ['transform', 'transform-gpu', 'transition-transform', '-translate-x-72'].forEach((klass) => {
      this.pageTarget.classList.toggle(klass);
    });
    this.menuTarget.classList.toggle('translate-x-full');

    this.openIconTarget.classList.toggle('hidden');
    this.closeIconTarget.classList.toggle('hidden');
  }

}
