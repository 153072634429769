import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'container', 'bg', 'innerContainer']

  toggle(e) {
    this.containerTarget.classList.toggle('hidden');
    this.setBackgroundHeight();
    this.setModalOffset();
    e.preventDefault();
  }

  // See comments in FiltersController
  setBackgroundHeight() {
    const modalHeight = this.containerTarget.clientHeight;
    const actualModalHeight = this.innerContainerTarget.clientHeight;

    if (modalHeight < actualModalHeight) {
      this.bgTarget.classList.remove('h-full');
    } else {
      this.bgTarget.classList.add('h-full');
    }
  }

  // You activate the filter modal at the top of the page, so we don't have to
  // do this, but here you might be half way down looking at a question; we
  // need to pop it in the right place. If you scrolled up, it'd be a bit
  // weird, but hey, that's modals for you. We could also make the inside of
  // the modal scroll, maybe?
  setModalOffset() {
    this.contentTarget.style.top = `${window.scrollY}px`;
  }
}
