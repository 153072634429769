import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["totalCost", "numberOfEmployees", "costPerEmployee",
    'minNumOfEmployeesDesktop', 'minNumOfEmployeesMobile', 'submitButton']

  // When a user clicks the calculate cost button, a request is made to
  // the server for the price and then the page is updated with the response.
  async calculateCost(event) {
    event.preventDefault();

    const numberOfEmployees = this.numberOfEmployeesTarget.value;
    // If the user inputs a number less than 1
    if (numberOfEmployees < 1) {
      this._revealMinNumOfEmployeesMessage()
      this._resetStylesForTotalCost()
      this._disableSubmitButton();
      return
    }

    this._hideMinNumOfEmployeesMessage();

    // Fetch the cost of the membership
    const params = { num_of_respondents: numberOfEmployees };
    const response = await fetch("/memberships/calculate_cost.json", {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });
    const parsedReponse = await response.json();

    // Update the total cost
    this.totalCostTarget.classList.remove('text-gray-500');
    this.totalCostTarget.innerHTML = `£${this._convertPenceToPounds(parsedReponse.cost)}`;
    // Update the cost per employee
    const costPerEmployeeInPence = Math.round(parsedReponse.cost / numberOfEmployees);
    const costPerEmployeeInPounds = this._convertPenceToPounds(costPerEmployeeInPence);
    this.costPerEmployeeTarget.innerHTML = `£${costPerEmployeeInPounds} per employee`;
    // Enable the submit button
    this._enableSubmitButton();
  }

  // Takes pence and converts it to pounds.
  // If the pounds value is a integer, then it returns the value as an integer.
  _convertPenceToPounds(costInPence) {
    if (costInPence % 100 == 0) {
      return costInPence / 100;
    } else {
      return Number.parseFloat(costInPence / 100).toFixed(2);
    }
  }

  _revealMinNumOfEmployeesMessage() {
    // There are two html targets, one for desktop...
    this.minNumOfEmployeesDesktopTarget.classList.remove('hidden');
    // and one for mobile
    this.minNumOfEmployeesMobileTarget.classList.remove('hidden');
  }

  _hideMinNumOfEmployeesMessage() {
    // There are two html targets, one for desktop...
    let classList = this.minNumOfEmployeesDesktopTarget.classList;
    if (!classList.contains('hidden')) { classList.add('hidden'); }
    // and one for mobile
    classList = this.minNumOfEmployeesMobileTarget.classList;
    if (!classList.contains('hidden')) { classList.add('hidden'); }
  }

  _enableSubmitButton() {
    const button = this.submitButtonTarget;
    // Enable the button
    button.disabled = false;
    // Change the styling
    const classList = button.classList;
    if (classList.contains('bg-grey-medium-bg')) { classList.remove('bg-grey-medium-bg'); }
    if (!classList.contains('green')) { classList.add('green'); }
  }

  _disableSubmitButton() {
    const button = this.submitButtonTarget;
    // Disabled the button
    button.disabled = true;
    // Change the styling
    const classList = button.classList;
    if (!classList.contains('bg-grey-medium-bg')) { classList.add('bg-grey-medium-bg'); }
    if (classList.contains('green')) { classList.remove('green'); }
  }

  // Reverts the HTML and CSS to the orginal content and styles
  // for the Cost section.
  _resetStylesForTotalCost() {
    const costClassList = this.totalCostTarget.classList;
    if (!costClassList.contains('text-gray-500')) {
      costClassList.add('text-gray-500');
    }
    this.totalCostTarget.innerHTML = "£";
    this.costPerEmployeeTarget.innerHTML = "Cost per employee";
  }
}
