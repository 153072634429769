import { Controller } from "stimulus";

// Responsible for the interactions with the ethnic group select dropdowns,
// which we show to employees that are submitting their assessment.
export default class extends Controller {
  static targets = ["group", "selection"];

  closeGroups() {
    this.groupTargets.forEach(el => {
      if (el.classList.contains('open')) {
        el.classList.remove('open');
      }
    });
  }

  // Toggles a group open/closed. Only one group can be open at a time, so it
  // also closes any other groups that are open.
  toggleGroupDropdown() {
    const currentGroup = event.target.closest('.group');
    if (currentGroup.classList.contains('open')) {
      this.closeGroups();
    } else {
      this.closeGroups();
      event.target.closest('.group').classList.add('open');
    }
  }

  unhighlightActiveGroup() {
    this.groupTargets.forEach(el => {
      if (el.classList.contains('active')) {
        el.classList.remove('active');
      }
    });
  }

  highlightGroup() {
   // If any of the other groups are currently highlighted, un-highlight them.
    this.unhighlightActiveGroup();

     // Highlight the correct group
    event.target.closest('.group').classList.toggle('active');

    const label = document.getElementById(`label-${event.target.value}`).innerText;

    // This will update the text in all the groups, but we hide all but
    // the active one.
    this.selectionTargets.forEach(el => el.innerText = label);

    this.closeGroups();
  }
}
